@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
    --crux-green: #7C764C;
    --crux-beige: #F7F4ED;
    --crux-brown: #5B4841;
    --crux-study: #F7F4ED;
    --crux-gray: #777169;
    --crux-gold: #A38C5E;
  }
}

html {
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  color: var(--title-color);
  background: var(--crux-beige);
  background-color: #F7F4ED;
}

$break-sm: 768px;
$break-md: 992px;
$break-xx: 1025px;
$break-lg: 1220px;
$break-xl: 1660px;

body {
  background-color: #F7F4ED;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  //padding-top: 77px;
  //margin-top: 50px;
  @media screen and (min-width: $break-xx) {
    //padding-top: 102px;
  }
}

.container {
  width: calc(100% - 720px);
  margin: 0 auto;
  //max-width: 1150px;
  @media screen and (min-width: $break-lg) {
    width: var(--width--container);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.containerAnimation {

  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.slick-prev:before, .slick-next:before {
  display: none;
}

//custom scroll bar
::-webkit-scrollbar {
  width: 6px; /* Širina palice za WebKit (Chrome, Safari, Opera) */
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Boja pozadine trake */
  border-radius: 10px; /* Zaobljenje ivica trake */
}

::-webkit-scrollbar-thumb {
  background-color: #7C7C7C; /* Boja palice */
  border-radius: 10px; /* Zaobljenje ivica palice */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Boja palice na hover */
}


input[type="checkbox"]:checked + label > span {
  background-color: #8F7E76; /* Primer: Indigo boja za selektovan čekboks */
  //border-color: white; /* Opcionalno: Promena boje ivice */
  border: 3px solid white;
  outline: 1px solid #8F7E76; /* Dodaje outline oko spana */
  outline-offset: 0.5px;
}

input[type="checkbox"]:checked + label > span::after {
  //content: '✓';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* globals.css */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

p {
  @apply font-inter;
}

input {
  @apply font-inter;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: calc(100% - 40px);
  }
}

.line {
  width: 100%;
  height: 1px;
  background: var(--border-brown);
  margin: 10px 0;
}

.ul-description-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  ul {
    width: calc(50% - 5px);

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.force-white-bg {
  @media (max-width: 768px) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: white !important;
  }

}

.beforeAfterContainer {
  .slick-track {
    display: flex;
    align-items: center;
  }
}
